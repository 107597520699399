<template>
  <el-form>
    <el-form-item class="title">
      <span>{{ $t('withdraw.otherMethod.withdrawalMethod') }}</span>
    </el-form-item>
    <SelectWithdraw
      v-if="topForm.showCC"
      name="creditCard"
      :showCreditCard="true"
      :amount="creditCardAmount"
      :reset="reset"
      :disabled="true"
    ></SelectWithdraw>
    <SelectWithdraw
      v-if="topForm.showOtherPayment"
      v-model="withdrawalType"
      name="withdrawalType"
      :availableTypes="availableTypes"
      :amount="withdrawAmount"
      :reset="reset"
      :disabled="disabled"
      :selectText="getSelectText()"
      @setWithdraw="setWithdraw"
    ></SelectWithdraw>

    <el-form-item v-if="showNote[withdrawalTypeId]">
      <div class="withdraw-note">
        {{ showNote[withdrawalTypeId].note }}
      </div>
    </el-form-item>

    <!-- withdrawal both credit card & none credit card message -->
    <el-form-item v-if="topForm.showCC && topForm.showOtherPayment">
      <div class="withdraw-note">{{ $t('withdraw.default.withdrawNote') }}</div>
    </el-form-item>

    <!-- only withdrawal credit card message -->
    <el-form-item v-if="topForm.onlyWithdrawalCc">
      <div class="withdraw-note">
        {{ $t('withdraw.default.onlyWithdrawalCcDesc', { ccAmount: ccWithdeawalAmountWithcurrency }) }}
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import SelectWithdraw from './SelectWithdraw'
import { apiGetNonCreditCardWithdrawTypeCP } from '@/resource'
import rounding from '@/util/rounding'
import splitThousands from '@/util/splitThousands'

export default {
  name: 'TopRight',
  components: { SelectWithdraw },
  props: {
    withdrawForm: Object,
    topForm: Object,
    reset: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      withdrawalType: null,
      availableTypes: [],
      withdrawData: {},
      ngaCountryCode: 5796,
      canCountryCode: 4575,
      ngaSupportCurrencies: ['USD', 'USC'],
      excludeCurrency: ['USD', 'USC'],
      withdrawalTypeId: null,
      showNote: {
        60: {
          note: '®Trade-mark of Interac Corp. Used under license',
        },
      },
    }
  },
  computed: {
    creditCardAmount() {
      return this.topForm.totalCcBalance >= this.withdrawData.amount
        ? this.withdrawData.amount
        : this.topForm.totalCcBalance
    },
    withdrawAmount() {
      return parseFloat(rounding(Math.round, this.withdrawData.amount - this.topForm.totalCcBalance, 2))
    },
    ccWithdeawalAmountWithcurrency() {
      return `${this.$options.filters.currencyToSymbol(this.withdrawData.currency)}${splitThousands(
        this.creditCardAmount,
        2,
      )}`
    },
    regulator() {
      return this.$store.state.common.regulator
    },
  },
  watch: {
    topForm: {
      handler() {
        if (!this.reset) {
          this.fetchWithdrawalMethod()
          this.withdrawData = { ...this.withdrawForm }
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchWithdrawalMethod() {
      apiGetNonCreditCardWithdrawTypeCP({
        accountNumber: this.withdrawForm.account,
      })
        .then(resp => {
          if (resp.status === 200) {
            // PU specific rule for filtering withdraw types
            let res = []
            const totalWithdrawTypes = resp?.data || []
            if (totalWithdrawTypes.length > 0) {
              res = totalWithdrawTypes
              if (this.excludeWithdrawTypes()) res = res.filter(type => ![4, 65].includes(type.id))
              if (this.filterNgaWithdrawTypes()) res = res.filter(type => type.id !== 2)
              if (this.filterCanWithdrawTypes()) res = res.filter(type => type.id !== 60)

              // filter Perfect Money (id = 61)
              if (this.filterPerfectMoneyType()) res = res.filter(type => type.id !== 61)
            }
            this.availableTypes = [...res]
          } else {
            this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' })
          }
        })
        .catch(() => {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' })
        })
    },
    setWithdraw(val) {
      this.withdrawalTypeId = val.withdrawalType
      this.$emit('setWithdraw', val)
    },
    getSelectText() {
      return this.topForm.showCC
        ? this.$t('withdraw.default.addSecondaryMethod')
        : this.$t('withdraw.default.addWithdrawalMethod')
    },
    filterNgaWithdrawTypes() {
      return (
        this.regulator === 'svg' &&
        this.topForm.userCountryCode == this.ngaCountryCode &&
        !this.ngaSupportCurrencies.includes(this.withdrawData.currency)
      )
    },
    filterCanWithdrawTypes() {
      return this.topForm.userCountryCode == this.canCountryCode && this.withdrawData.currency !== 'CAD'
    },
    excludeWithdrawTypes() {
      return !this.excludeCurrency.includes(this.withdrawData.currency)
    },
    filterPerfectMoneyType() {
      const allowedCurrencies = ['USD', 'USC', 'EUR']
      return this.withdrawForm?.currency ? !allowedCurrencies.includes(this.withdrawForm?.currency) : true
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: 700;
  color: $text-primary;
}

.withdraw-note {
  font-size: 13px;
  color: $text-secondary;
}
</style>
