<template>
  <div id="usdtWithdraw" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img src="@/assets/images/withdraw/usdt_icon.png" alt="usdt_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li>
            <SelectForm
              v-model="TransferForm.chain"
              :label="$t('withdraw.usdt.field.chain')"
              name="chain"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="option in formChainOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></el-option>
            </SelectForm>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.usdt.field.address')" prop="address">
              <el-input
                v-model.trim="TransferForm.address"
                id="address"
                class="plain-input"
                auto-complete="new-password"
                data-testid="cryptoWalletAddress"
                maxlength="64"
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.notes"
                id="importantNotes"
                auto-complete="new-password"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
        <div class="alert-info">
          <img class="alert_info_icon" src="@/assets/images/withdraw/info.png" alt="info_img" />
          <p>{{ $t(`deposit.crypto.note`) }}</p>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import SelectForm from '@/components/form/Select';

export default {
  name: 'usdtWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'currency', 'followerResults'],
  mixins: [mixin],
  components: { SelectForm },
  data() {
    return {
      formChainOptions: [
        { text: 'TRC20', value: 'trc20' },
        { text: 'ERC20', value: 'erc20' }
      ],
      TransferForm: {
        address: '',
        notes: '',
        chain: 'trc20'
      },
      TransferFormRules: {
        address: [
          {
            required: true,
            message: this.$t('withdraw.usdt.formValidation.addressReq'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const chainType = this.TransferForm.chain;

              if (chainType === 'trc20' && !/^T/.test(value)) {
                // callback(new Error(this.$t('withdraw.usdt.formValidation.walletFormatIncorrect', { type: 'TRC20' })));
                callback(new Error(this.$t('withdraw.usdt.formValidation.trc20WalletFormatIncorrect')));
              } else if (chainType === 'erc20' && !/^0x/.test(value)) {
                // callback(new Error(this.$t('withdraw.usdt.formValidation.walletFormatIncorrect', { type: 'ERC20' })));
                callback(new Error(this.$t('withdraw.usdt.formValidation.erc20WalletFormatIncorrect')));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        chain: [
          {
            required: true,
            message: this.$t('withdraw.usdt.formValidation.chain'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              this.$refs.TransferForm.validateField('address');
              callback();
            },
            trigger: 'change'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        cryptoWalletAddress: this.TransferForm.address,
        importantNotes: this.TransferForm.notes,
        currency: this.currency,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
@import '@/assets/css/components/common/SelectLine';
</style>
