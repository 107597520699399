import { apiPaymentSelection } from '@/resource'

export default {
  data() {
    return {
      dropdownTranslationObj: {
        1: this.$t('withdraw.default.anotherCC'),
        2: this.$t('withdraw.default.anotherBA'),
        5: this.$t('withdraw.default.anotherBA'),
        6: this.$t('withdraw.default.anotherBA'),
        8: this.$t('withdraw.default.anotherBA'),
        9: this.$t('withdraw.default.anotherBA'),
        24: this.$t('withdraw.default.anotherBA'),
        35: this.$t('withdraw.default.anotherBA'),
        39: this.$t('withdraw.default.anotherBA'),
        40: this.$t('withdraw.default.anotherBA'),
        42: this.$t('withdraw.default.anotherBA'),
        50: this.$t('withdraw.default.anotherBA'),
        51: this.$t('withdraw.default.anotherBA'),
        52: this.$t('withdraw.default.anotherBA'),
        53: this.$t('withdraw.default.anotherBA'),
        54: this.$t('withdraw.default.anotherBA'),
        55: this.$t('withdraw.default.anotherBA'),
        56: this.$t('withdraw.default.anotherBA'),
        57: this.$t('withdraw.default.anotherBA'),
        58: this.$t('withdraw.default.anotherBA'),
        60: this.$t('withdraw.default.anotherBA'),
        65: this.$t('withdraw.default.anotherBA'),
      },
      availableCards: [],
      dialog: {
        show: false,
        text: '',
        method: '',
      },
      TransferForm: {
        selectedCardID: null,
      },
      TransferFormRules: {
        selectedCardID: [
          {
            required: true,
            message: this.$t('common.formValidation.cardReq'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    cardInfoDropdown: function(value, item, type, dropdownTranslationObj) {
      if (value === undefined || value === null) return ''
      if (item.cardFirstSixDigits && item.cardLastFourDigits) {
        value = item.cardFirstSixDigits + '** ****' + item.cardLastFourDigits
      } else if (item.accountNumber) {
        value = '****' + item.accountNumber.slice(-4)
      } else {
        value = dropdownTranslationObj[type]
      }
      return value
    },
  },
  mounted() {
    apiPaymentSelection({ mtAcc: this.accountNumber, paymentType: this.withdrawalType })
      .then(resp => {
        this.availableCards = Array.isArray(resp.data) ? resp.data : []
      })
      .finally(resp => {
        this.addCustomOption()
      })
  },
  methods: {
    addCustomOption() {
      this.availableCards.push({ id: -1 })
    },
    latinNumberValidator(value) {
      return value.replace(/[^a-z0-9 ]/gi, '')
    },
  },
}
