<template>
  <!-- Transfer Int -->
  <div class="form-bottom-row line clearfix">
    <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules" class="form_container">
      <div class="form_row bank-transfer">
        <ul class="form-list clearfix">
          <li>
            <el-form-item :label="$t('common.field.bankName')" prop="bankName_transferInt">
              <el-input
                v-model="TransferForm.bankName_transferInt"
                id="bankName_transferInt"
                auto-complete="new-password"
                data-testid="bankName"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.bankAddress')" prop="bankAddress_transferInt">
              <el-input
                v-model="TransferForm.bankAddress_transferInt"
                id="bankAddress_transferInt"
                auto-complete="new-password"
                data-testid="bankAddress"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="form-list clearfix">
          <li>
            <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="bankBeneficiaryName_transferInt">
              <el-input
                v-model="TransferForm.bankBeneficiaryName_transferInt"
                id="bankBeneficiaryName_transferInt"
                auto-complete="new-password"
                data-testid="beneficiaryName"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.bankAccOrIBAN')" prop="accountNumber">
              <el-input
                v-model.trim="TransferForm.accountNumber"
                id="accountNumber"
                auto-complete="new-password"
                data-testid="isdisabled"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="form-list clearfix">
          <li>
            <el-form-item :label="$t('common.field.bankHolderAddress')" prop="accountHolderAddress_transferInt">
              <el-input
                v-model="TransferForm.accountHolderAddress_transferInt"
                id="accountHolderAddress_transferInt"
                auto-complete="new-password"
                data-testid="holderAddress"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.swift')" prop="swift_transferInt">
              <el-input
                v-model.trim="TransferForm.swift_transferInt"
                id="swift_transferInt"
                auto-complete="new-password"
                data-testid="swift"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="form-list clearfix" v-if="userCountryCode == canCountryCode">
          <li>
            <el-form-item :label="$t('common.field.transitNumber')" prop="transitNumber">
              <el-input
                v-model.trim="TransferForm.transitNumber"
                id="transitNumber"
                auto-complete="new-password"
                data-testid="transitNumber"
                class="plain-input"
                placeholder=""
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.institutionNumber')" prop="institutionNumber">
              <el-input
                v-model.trim="TransferForm.institutionNumber"
                id="institutionNumber"
                auto-complete="new-password"
                data-testid="institutionNumber"
                class="plain-input"
                placeholder=""
              />
            </el-form-item>
          </li>
        </ul>
        <ul class="form-list clearfix">
          <li>
            <el-form-item :label="$t('common.field.abaSortCode')" prop="abaCode_transferInt">
              <el-input
                v-model.trim="TransferForm.abaCode_transferInt"
                id="abaCode_transferInt"
                auto-complete="new-password"
                data-testid="sortCode"
                class="plain-input"
                placeholder=""
                :disabled="isdisabled"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.notes_transferInt"
                id="importantNotes"
                auto-complete="new-password"
                data-testid="importantNotes"
                class="plain-input"
                placeholder=""
              />
            </el-form-item>
          </li>
        </ul>
      </div>

      <div class="form_upload">
        <el-form-item class="upload" prop="uploadedFile">
          <div class="labelTitle">
            <span class="required_icon">*</span>
            <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
          </div>
          <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile"></vUpload>
        </el-form-item>
      </div>

      <div class="transfer-info-container">
        <p class="int_p" v-html="$t('withdraw.transfer.info', { platform: $config.info.fullName })"></p>
      </div>

      <div class="checkbox-wrapper" v-if="!TransferForm.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
          {{ $t('withdraw.default.remember') }}
        </el-checkbox>
      </div>
    </el-form>
  </div>
</template>

<script>
import mixin from '@/mixins';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/withdraw/withdrawUpload';

export default {
  name: 'TransferIntWithdraw',
  props: [
    'accountNumber',
    'amount',
    'withdrawalType',
    'userCountryCode',
    'selectedCardInfo',
    'transferCountryString',
    'chooseWithdrawalType',
    'followerResults'
  ],
  components: { vUpload },
  mixins: [mixin, bankTransferMixin],
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      TransferForm: {
        bankName_transferInt: '',
        bankAddress_transferInt: '',
        bankBeneficiaryName_transferInt: '',
        accountNumber: '',
        accountHolderAddress_transferInt: '',
        swift_transferInt: '',
        abaCode_transferInt: '',
        notes_transferInt: '',
        transitNumber: '',
        institutionNumber: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'blur'
          }
        ],
        bankAddress_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: 'blur'
          }
        ],
        bankBeneficiaryName_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        accountHolderAddress_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.accHolderAddress'),
            trigger: 'blur'
          }
        ],
        swift_transferInt: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      canCountryCode: 4575 //Canada
    };
  },
  watch: {
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        if (value && value.id !== -1) {
          var notesObj = {};
          if (value.importantNotes) {
            var properties = value.importantNotes.split(', ');
            properties.forEach(function(property) {
              var tup = property.split(':');
              notesObj[tup[0]] = tup[1];
            });
            console.log(notesObj);
          }
          this.TransferForm = {
            bankName_transferInt: value.bankName,
            bankAddress_transferInt: value.bankAddress,
            accountNumber: value.accountNumber,
            bankBeneficiaryName_transferInt: value.beneficiaryName,
            accountHolderAddress_transferInt: value.holderAddress,
            abaCode_transferInt: value.sortCode,
            swift_transferInt: value.swift,
            notes_transferInt:
              value.importantNotes && this.userCountryCode == this.canCountryCode
                ? notesObj['notes']
                : value.importantNotes && this.userCountryCode != this.canCountryCode
                ? value.importantNotes
                : '',
            userPaymentInfoId: value.id,
            transitNumber: value.importantNotes ? notesObj['Transit Number'] : '',
            institutionNumber: value.importantNotes ? notesObj['Institution Number'] : ''
          };
          this.isdisabled = true;
        } else {
          this.isdisabled = false;
          this.TransferForm.userPaymentInfoId = null;
        }
      }
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['TransferForm'].validateField('uploadedFile');
    },
    getFormData() {
      if (this.userCountryCode == this.canCountryCode) {
        this.TransferForm.notes_transferInt = `Transit Number: ${this.TransferForm.transitNumber}, Institution Number: ${this.TransferForm.institutionNumber}, notes: ${this.TransferForm.notes_transferInt}`;
      }
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        country: parseInt(this.transferCountryString),
        bankName: this.TransferForm.bankName_transferInt,
        bankAddress: this.TransferForm.bankAddress_transferInt,
        accountNumber: this.TransferForm.accountNumber,
        beneficiaryName: this.TransferForm.bankBeneficiaryName_transferInt,
        holderAddress: this.TransferForm.accountHolderAddress_transferInt,
        sortCode: this.TransferForm.abaCode_transferInt,
        swift: this.TransferForm.swift_transferInt,
        importantNotes: this.TransferForm.notes_transferInt,
        fileList: this.fileList,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .form_row.bank-transfer {
    max-width: 66.6% !important;

    .form-list {
      max-width: 100% !important;
    }
  }
}
</style>
