<template>
  <!-- SticPay -->
  <div id="sticpayForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/sticpay_icon.png" alt="sticpay_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list two-fields clearfix">
          <li>
            <el-form-item :label="$t('withdraw.sticpay.field.email')" prop="sticpayEmial_sticpay">
              <el-input
                v-model="TransferForm.sticpayEmial_sticpay"
                id="sticpayEmial_sticpay"
                type="email"
                auto-complete="new-password"
                data-testid="sticpayEmail"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="notes_sticpay">
              <el-input
                v-model="TransferForm.notes_sticpay"
                id="notes_sticpay"
                auto-complete="new-password"
                class="plain-input"
                data-testid="importantNotes"
              />
            </el-form-item>
          </li>
        </ul>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import { validateEmail } from '@/util/validation';

export default {
  name: 'SticpayWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        sticpayEmial_sticpay: '',
        notes_sticpay: ''
      },
      TransferFormRules: {
        sticpayEmial_sticpay: [
          {
            required: true,
            message: this.$t('withdraw.sticpay.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        skrillEmail: this.TransferForm.sticpayEmial_sticpay,
        importantNotes: this.TransferForm.notes_sticpay,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
