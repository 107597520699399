<template>
  <!-- Thai/Mala/Viet-->
  <div id="thaiForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img src="@/assets/images/withdraw/vietna_icon.png" alt="vietna_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li class="fl">
            <SelectForm
              v-model="TransferForm.selectedCardID"
              :label="$t('withdraw.default.selectBA')"
              name="selectedCardID"
              data-testid="selectedCardID"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
        </ul>
        <div v-if="TransferForm.selectedCardID || TransferForm.selectedCardID === 0">
          <ul class="form-list clearfix">
            <li>
              <SelectForm
                v-model="TransferForm.bankName_thai"
                class="plain-select"
                :label="$t('common.field.bankName')"
                name="bankName_thai"
                data-testid="bankName"
                popperClass="withdraw-select"
                :disabled="isdisabled"
              >
                <el-option
                  v-for="(item, index) in bankList"
                  :key="index"
                  :value="item"
                  :label="index"
                  :data-testid="item"
                ></el-option>
              </SelectForm>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankAddress')" prop="bankAddress_thai">
                <el-input
                  v-model="TransferForm.bankAddress_thai"
                  id="bankAddress_thai"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="bankAddress"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankBeneficiaryName')" prop="bankBeneficiaryName_thai">
                <el-input
                  v-model="TransferForm.bankBeneficiaryName_thai"
                  id="bankBeneficiaryName_thai"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="beneficiaryName"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
                <el-input
                  v-model.trim="TransferForm.accountNumber"
                  id="accountNumber"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="accountNumber"
                />
              </el-form-item>
            </li>
            <!-- only Thailand -->
            <li v-if="withdrawalType == 5">
              <el-form-item :label="$t('common.field.bankHolderAddress')" prop="holderAddress_thai">
                <el-input
                  v-model="TransferForm.holderAddress_thai"
                  id="holderAddress_thai"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="holderAddress"
                />
              </el-form-item>
            </li>
            <!-- only Thailand -->
            <li v-if="withdrawalType == 5">
              <el-form-item :label="$t('common.field.swift')" prop="Swift_thai">
                <el-input
                  v-model="TransferForm.Swift_thai"
                  id="Swift_thai"
                  auto-complete="new-password"
                  class="plain-input"
                  :disabled="isdisabled"
                  data-testid="swift"
                />
              </el-form-item>
            </li>
            <li>
              <el-form-item :label="$t('common.field.imptNotes')" prop="notes_thai">
                <el-input
                  v-model="TransferForm.notes_thai"
                  id="notes_thai"
                  auto-complete="new-password"
                  class="plain-input"
                  data-testid="importantNotes"
                />
              </el-form-item>
            </li>
          </ul>
          <div class="checkbox-wrapper" v-if="!TransferForm.userPaymentInfoId">
            <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
              {{ $t('withdraw.default.remember') }}
            </el-checkbox>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import SelectForm from '@/components/form/Select';

export default {
  name: 'ThailandWithdraw',
  props: {
    accountNumber: Number,
    amount: Number,
    withdrawalType: Number,
    followerResults: Array,
    bankList: Object
  },
  components: { SelectForm },
  mixins: [mixin, bankTransferMixin, withdrawlMixin],
  data() {
    return {
      TransferForm: {
        bankName_thai: '',
        bankAddress_thai: '',
        holderAddress_thai: '',
        Swift_thai: '',
        bankBeneficiaryName_thai: '',
        accountNumber: '',
        notes_thai: '',
        userPaymentInfoId: null
      },
      TransferFormRules: {
        bankName_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        bankAddress_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.bankAddressReq'),
            trigger: ['blur', 'change']
          }
        ],
        Swift_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.swiftReq'),
            trigger: ['blur', 'change']
          }
        ],
        holderAddress_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.accHolderAddress'),
            trigger: ['blur', 'change']
          }
        ],
        bankBeneficiaryName_thai: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBeneficiaryNameReq'),
            trigger: ['blur', 'change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ]
      }
    };
  },
  watch: {
    'TransferForm.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);
      if (selectedCardInfo && selectedCardInfo.id !== -1) {
        this.TransferForm = {
          selectedCardID: value,
          bankName_thai: selectedCardInfo.bankName,
          bankAddress_thai: selectedCardInfo.bankAddress,
          Swift_thai: selectedCardInfo.swift,
          holderAddress_thai: selectedCardInfo.holderAddress,
          bankBeneficiaryName_thai: selectedCardInfo.beneficiaryName,
          accountNumber: selectedCardInfo.accountNumber,
          notes_thai: selectedCardInfo.importantNotes,
          userPaymentInfoId: selectedCardInfo.id
        };
        this.isdisabled = true;
      } else {
        //choose another
        this.TransferForm = {
          selectedCardID: value,
          bankName_thai: '',
          bankAddress_thai: '',
          Swift_thai: '',
          holderAddress_thai: '',
          bankBeneficiaryName_thai: '',
          accountNumber: '',
          notes_thai: '',
          userPaymentInfoId: null
        };
        this.isdisabled = false;
      }
    },
    'TransferForm.accountNumber'(value) {
      this.TransferForm.accountNumber = this.latinNumberValidator(value);
    },
    'TransferForm.bankBeneficiaryName_thai'(value) {
      this.TransferForm.bankBeneficiaryName_thai = this.latinNumberValidator(value);
    }
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName_thai,
        bankAddress: this.TransferForm.bankAddress_thai,
        swift: this.TransferForm.Swift_thai,
        holderAddress: this.TransferForm.holderAddress_thai,
        beneficiaryName: this.TransferForm.bankBeneficiaryName_thai,
        accountNumber: this.TransferForm.accountNumber,
        importantNotes: this.TransferForm.notes_thai,
        isRememberInfo: this.TransferForm.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.TransferForm.userPaymentInfoId ? this.TransferForm.userPaymentInfoId : '',
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
@import '@/assets/css/components/common/SelectLine';
</style>
