<template>
  <div class="credit-card-group">
    <div class="credit-card-container" v-for="(item, index) in creditCardList" :key="index">
      <div v-if="displayList(item.availableCard, item.isShow)">
        <!-- credit card item tab -->
        <div class="credit-card-tab">
          <!-- tab content -->
          <!-- icon -->
          <!-- <div
            v-if="[4, 5].includes(getFirstNum(item.creditCard.card_begin_six_digits))"
            class="credit-card-icon"
            :class="[creditCardImg[getFirstNum(item.creditCard.card_begin_six_digits)]]"
          /> -->
          <el-form class="title_form" label-position="top">
            <el-row :gutter="20">
              <el-col :span="8" :xs="24">
                <el-form-item :label="$t('common.withdrawChannel.creditcard')">
                  <el-select
                    v-model="form[`form_${index}`]"
                    :disabled="disabledSelect"
                    :data-testid="`form_${index}`"
                    @change="updateCcForm(index)"
                    class="credit-select"
                    popper-class="withdraw-select"
                  >
                    <el-option
                      v-for="(list, i) in item.selectedCardList"
                      v-show="list.availableCard"
                      :key="i + index"
                      :value="i + index"
                      :label="setCreditCardNumber(list.creditCard)"
                      :data-testid="i + index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24">
                <el-form-item :label="$t('common.field.availableAmount')">
                  <span>${{ item.balance }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="8" :xs="24">
                <el-form-item :label="$t('common.field.amt')">
                  <span>${{ item.showBalance }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <!-- arrow icon -->
          <div class="select-icon" :class="{ open: openStatusList[index] }">
            <svg-icon iconClass="arrowDown" @click="toggleCreditCardContent(index)"></svg-icon>
          </div>
        </div>

        <!-- credit card content -->
        <div v-show="openStatusList[index]">
          <CreditCardWithdraw
            :ref="'creditCardWithdraw' + index"
            :ccDetail="item"
            :creditCardNumber="setCreditCardNumber(item.selectedCardList[0].creditCard)"
            :archivable="item.creditCard.is_del === 0 && !item.isPending"
            :isExpired="item.isExpired"
          ></CreditCardWithdraw>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardWithdraw from '@/components/withdraw/types/CreditCardWithdraw'

export default {
  name: 'CcTab',
  components: { CreditCardWithdraw },
  props: {
    creditCardList: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    showAvailable: {
      type: Boolean,
      default: true,
    },
    amount: Number,
    disableField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creditCardImg: {
        4: 'visa',
        5: 'master-card',
      },
      openStatusList: {},
    }
  },
  watch: {
    creditCardList: {
      immediate: true,
      handler(newValue) {
        if (newValue.length) {
          const obj = {}
          for (let i = 0; i < newValue.length; i++) {
            obj[i] = false
          }
          this.openStatusList = { ...obj }
        }
      },
    },
  },
  computed: {
    disabledSelect() {
      if (!this.showAvailable) {
        return true
      }
      return this.disableField
    },
  },
  methods: {
    updateCcForm(index) {
      this.$emit('updateCcForm', index)
    },
    setCreditCardNumber(creditCard) {
      this.firstCreditCardNum = parseInt(creditCard.card_begin_six_digits.split('')[0])
      return `${creditCard.card_begin_six_digits}** ****${creditCard.card_last_four_digits}`
    },
    displayList(availableCard, isShow) {
      if (this.showAvailable) {
        return availableCard && isShow
      }
      return !availableCard
    },
    tabIndex(index) {
      return this.showAvailable ? `tab${index}` : `tab-unavailable-${index}`
    },
    getFirstNum(val) {
      return parseInt(val.split('')[0])
    },
    toggleCreditCardContent(index) {
      this.openStatusList[index] = !this.openStatusList[index]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/ccTab.scss';
</style>
