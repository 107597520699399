<template>
  <div class="div_dialog">
    <el-dialog
      :visible.sync="show"
      :modalAppendToBody="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="0"
    >
      <slot name="header"></slot>
      <div class="dialog-body">
        <slot name="body"></slot>
        <p v-html="text"></p>
        <div class="btn-box">
          <slot name="btn"></slot>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogVersion2',
  props: {
    show: Boolean,
    text: String
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dialog/v2/dialog.scss';
</style>
