<template>
  <div class="box box-card-mid">
    <!-- available Credit Card -->
    <div>
      <!-- head -->
      <el-row :gutter="40" justify="space-between" type="flex" class="head-wrapper">
        <el-col :xs="24" :sm="12">
          <div class="head-title">{{ $t('common.withdrawChannel.manageCreditCards') }}</div>
        </el-col>
        <el-col :xs="24" :sm="12" class="total-amount"> {{ $t('common.field.amt') }}: ${{ amount }} </el-col>
      </el-row>

      <!-- tab area -->
      <CcTab
        ref="creditCardTab"
        :creditCardList="creditCardList"
        @updateCcForm="updateCcForm"
        :amount="amount"
        :form="form"
        :disableField="disableField"
      />
    </div>

    <!-- Expired Credit Card -->
    <div class="expired-cc" v-if="hasUnavailableCard">
      <!-- head -->
      <div class="head-wrapper">
        <div class="head-title">{{ $t('common.withdrawChannel.expiredCard') }} ({{ expriredCount }})</div>
        <span class="hide-cc" @click="hideExpiredCreditcard = !hideExpiredCreditcard">
          {{ hideExpiredCreditcard ? $t('common.button.showAll') : $t('common.button.hide') }}
        </span>
      </div>

      <!-- tab area -->
      <div v-show="!hideExpiredCreditcard">
        <CcTab
          ref="creditCardUnavailableTab"
          :creditCardList="creditCardList"
          @updateCcForm="updateCcForm"
          :amount="amount"
          :form="form"
          :showAvailable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rounding from '@/util/rounding';
import CreditCardWithdraw from '@/components/withdraw/types/CreditCardWithdraw';
import CcTab from './CcTab.vue';

export default {
  name: 'ccMethod',
  components: { CreditCardWithdraw, CcTab },
  props: { withdrawForm: Object, topForm: Object, isShowOtherPayment: Boolean },
  data() {
    return {
      form: {},
      creditCardList: [],
      unavailableCardList: [],
      allCcComponent: [],
      hasUnavailableCard: false,
      hideExpiredCreditcard: true
    };
  },
  mounted() {
    this.creditCardList = JSON.parse(JSON.stringify(this.topForm.creditCardBalances));
    this.creditCardList = this.creditCardList.map(m => {
      const cc = m.creditCard;
      const archived = cc.is_del === 1;
      const expired = m.isExpired;
      const refunded = m.balance <= 0;
      const pending = m.isPending;
      const available = !archived && !expired && !refunded && !pending;
      m.availableCard = available;
      if (!available) {
        this.hasUnavailableCard = true;
      }
      return m;
    });
  },
  computed: {
    amount() {
      return this.topForm.totalCcBalance >= this.withdrawForm.amount
        ? this.withdrawForm.amount
        : this.topForm.totalCcBalance;
    },
    disableField() {
      return this.amount < this.withdrawForm.amount;
    },
    expriredCount() {
      let count = 0;
      this.creditCardList.forEach(creditCard => {
        if (!creditCard.availableCard) {
          count++;
        }
      });
      return count;
    }
  },
  watch: {
    creditCardList() {
      let balance = 0;
      let amount = this.amount;
      let form = {};
      let count = this.creditCardList.length;
      let hasPendingCard = false;

      this.creditCardList.forEach((m, i) => {
        m.showBalance = m.balance;
        form[`form_${i}`] = i;
        m.selectedCardList = [...JSON.parse(JSON.stringify(this.creditCardList)).slice(i, count)];
        if (balance < this.amount) {
          m.isShow = true;

          if (m.availableCard) {
            balance = balance + m.showBalance;
            balance > this.amount ? (m.showBalance = amount) : (amount = this.roundingNumber(amount - m.showBalance));
          }
        } else {
          m.isShow = false;
        }
        if (!hasPendingCard && m.isPending && m.balance > 0) {
          hasPendingCard = true;
        }
      });

      const hasAvailableCard = this.creditCardList.some(card => card.availableCard);
      const disableOtherMethod = !hasAvailableCard && hasPendingCard;
      this.$emit('disableOtherMethod', disableOtherMethod);
      this.form = form;
    }
  },
  methods: {
    roundingNumber(number) {
      return parseFloat(rounding(Math.ceil, number, 2));
    },
    updateCcForm(index) {
      let copyArr = [...this.creditCardList];
      copyArr = this.swapArray(copyArr, index, this.form[`form_${index}`]);
      this.creditCardList = [...copyArr];
    },
    swapArray(array, indexA, indexB) {
      let temp = array[indexA];
      array[indexA] = array[indexB];
      array[indexB] = temp;
      return array;
    },
    getFormOfChild() {
      let getFormOfAvailableComponent = [];
      const refs = this.$refs.creditCardTab.$refs;
      this.allCcComponent.map(m => {
        if (refs[m].length > 0) {
          getFormOfAvailableComponent.push(refs[m][0].getFormData());
        }
      });

      return getFormOfAvailableComponent;
    },
    getFormDataOfChild() {
      return {
        qAccount: this.withdrawForm.account,
        amount: this.amount,
        withdrawalType: 1,
        creditCardWithdrawalRequests: [...this.getFormOfChild()]
      };
    },
    submitForm() {
      let availableComponent = [];

      const refs = this.$refs.creditCardTab.$refs;

      this.allCcComponent = Object.keys(refs);
      this.allCcComponent.map(m => {
        if (refs[m].length > 0) {
          const validForm = refs[m][0].$refs['TransferForm'].validate();
          availableComponent.push(validForm);
        }
      });

      return availableComponent;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/withdraw/ccMethods.scss';
</style>
