<template>
  <div class="common-dialog-wrapper" ref="DialogVersion1">
    <el-dialog
      :visible="visible"
      :close-on-click-modal="closeOnClickModal"
      center
      class="dialog"
      :append-to-body="needToBody"
      :fullscreen="needFullscreen || fullscreen"
      :class="extraClass"
      top="0"
      @close="close"
    >
      <div slot="title">
        <slot name="header"></slot>
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
      <div v-if="visibleFooter" slot="footer" class="dialog-footer">
        <div @click="showLiveChat" class="chat" v-if="!hideChat" data-testid="liveChat">
          <img src="@/assets/images/dialog/chat.png" alt />
          <div v-html="$t('common.liveChat.desc')"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'GeneralDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    visibleFooter: {
      type: Boolean,
      default: false,
    },
    hideChat: {
      type: Boolean,
      default: false,
    },
    needFullscreen: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    needToBody: {
      type: Boolean,
      default: false,
    },
    extraClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fullscreen: false,
    }
  },
  watch: {
    visible(bool) {
      if (bool) this.$nextTick(() => this.setDialog())
    },
  },
  created() {
    window.addEventListener('resize', this.setDialog)
  },
  destroyed() {
    window.removeEventListener('resize', this.setDialog)
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    setDialog() {
      this.fullscreen = false
      this.$nextTick(() => {
        const windowH = window.innerHeight
        const elDialog = this.$refs.DialogVersion1

        if (elDialog) {
          const dialogH = elDialog.getElementsByClassName('el-dialog')[0].offsetHeight
          this.fullscreen = this.isFullscreen(dialogH, windowH)
        }
      })
    },
    isFullscreen(dialogH, windowH) {
      return dialogH >= windowH ? true : false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dialog/v1/dialog.scss';
</style>
