<template>
  <!-- UnionPay -->
  <div id="unionPayForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/union_pay_icon.png" alt="union_pay_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list clearfix">
          <li>
            <SelectForm
              v-model="TransferForm.bankCard"
              :label="$t('common.field.selectWithdrawCard')"
              name="bankCard"
              class="plain-select"
              popperClass="withdraw-select"
            >
              <el-option
                v-for="card in TransferForm.cards"
                :key="card.id"
                :value="card.id"
                :label="card.showNumber"
              ></el-option>
            </SelectForm>
          </li>
          <li>
            <el-form-item :label="$t('common.field.bankName')" prop="bankName_unionpay">
              <el-input
                v-model="TransferForm.bankName_unionpay"
                id="bankName_unionpay"
                class="plain-input"
                :readonly="true"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.bankAccNum')" prop="accountNumber">
              <el-input
                v-model.trim="TransferForm.accountNumber"
                id="accountNumber"
                class="plain-input"
                :readonly="true"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.bankBranch')" prop="bankBranch_unionpay">
              <el-input
                v-model="TransferForm.bankBranch_unionpay"
                id="bankBranch_unionpay"
                class="plain-input"
                :readonly="true"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.bankAccName')" prop="bankAccountName_unionpay">
              <el-input
                v-model="TransferForm.bankAccountName_unionpay"
                id="bankAccountName_unionpay"
                class="plain-input"
                :readonly="true"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.notes_unionpay"
                id="importantNotes"
                class="plain-input"
                auto-complete="new-password"
              />
            </el-form-item>
          </li>
        </ul>
      </el-form>
    </div>
    <InteractiveDialog :show.sync="unionPayDialog.show" :text="unionPayDialog.text">
      <template v-slot:btn>
        <el-button class="el-button btn-blue" @click="goToWithDrawDetail">
          {{ $t('common.button.confirm') }}
        </el-button>
      </template>
    </InteractiveDialog>
  </div>
</template>

<script>
import { apiCardInformation } from '@/resource';
import mixin from '@/mixins';
import InteractiveDialog from '@/components/dialog/interactive/Dialog.vue';
import SelectForm from '@/components/form/Select';

export default {
  name: 'UnionPayWithdrawCps',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  mixins: [mixin],
  components: { InteractiveDialog, SelectForm },
  data() {
    return {
      TransferForm: {
        bankName_unionpay: '',
        accountNumber: '',
        bankBranch_unionpay: '',
        bankAccountName_unionpay: '',
        notes_unionpay: '',
        cards: [],
        bankCard: ''
      },
      TransferFormRules: {
        bankName_unionpay: [
          {
            required: true,
            message: this.$t('common.formValidation.bankNameReq'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccount,
            trigger: ['blur', 'change']
          }
        ],
        bankBranch_unionpay: [
          {
            required: true,
            message: this.$t('common.formValidation.bankBranchReq'),
            trigger: 'blur'
          }
        ],
        bankAccountName_unionpay: [
          {
            required: true,
            message: this.$t('common.formValidation.accNameReq'),
            trigger: 'blur'
          }
        ],
        bankCard: [
          {
            required: true,
            message: this.$t('common.formValidation.bankCardReq'),
            trigger: 'blur'
          }
        ]
      },
      unionPayDialog: {
        show: false,
        text: ''
      }
    };
  },
  watch: {
    'TransferForm.bankCard'(id) {
      const card = this.TransferForm.cards.find(item => item.id == id);
      this.TransferForm.bankName_unionpay = card.bank_name;
      this.TransferForm.bankBranch_unionpay = card.branch_name;
      this.TransferForm.accountNumber = card.showNumber;
      this.TransferForm.bankAccountName_unionpay = card.card_holder_name;
    }
  },
  methods: {
    queryCards() {
      return apiCardInformation(0)
        .then(resp => {
          if (resp.data.code == 0) {
            return resp.data.data;
          } else {
            return [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    goToWithDrawDetail() {
      this.$router.push('paymentDetails');
    },
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        bankName: this.TransferForm.bankName_unionpay,
        accountNumber: this.TransferForm.bankCard,
        bankBranchName: this.TransferForm.bankBranch_unionpay,
        bankAccountName: this.TransferForm.bankAccountName_unionpay,
        importantNotes: this.TransferForm.notes_unionpay,
        followerResultIds: this.followerResults
      };
    }
  },
  mounted() {
    this.queryCards().then(cards => {
      if (cards == null || cards.length == 0) {
        this.unionPayDialog = {
          show: true,
          text: this.$t('withdraw.default.noCardWarn')
        };
      } else {
        this.TransferForm.cards = cards;
        this.TransferForm.cards.forEach(card => {
          card.id = card.card_number;
          card.showNumber =
            card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        });
        this.TransferForm.bankName_unionpay = cards[0].bank_name;
        this.TransferForm.bankBranch_unionpay = cards[0].branch_name;
        this.TransferForm.accountNumber = cards[0].showNumber;
        this.TransferForm.bankAccountName_unionpay = cards[0].card_holder_name;
        this.TransferForm.bankCard = cards[0].card_number;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/common/SelectLine';
</style>
