<template>
  <!-- Neteller -->
  <div id="netellerForm" class="form-bottom-row line clearfix">
    <div class="fr img-row">
      <img class="long-strip-icon" src="@/assets/images/withdraw/neteller_icon.png" alt="neteller_icon" />
    </div>
    <div class="fl form_row">
      <el-form :model="TransferForm" ref="TransferForm" :rules="TransferFormRules">
        <ul class="form-list two-fields clearfix">
          <li>
            <el-form-item :label="$t('withdraw.neteller.field.email')" prop="netellerEmial">
              <el-input
                v-model="TransferForm.netellerEmial"
                id="netellerEmial"
                type="email"
                auto-complete="new-password"
                data-testid="netellerEmail"
                class="plain-input"
              />
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('common.field.imptNotes')" prop="importantNotes">
              <el-input
                v-model="TransferForm.importantNotes"
                id="importantNotes"
                auto-complete="new-password"
                class="plain-input"
              />
            </el-form-item>
          </li>
        </ul>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins';
import { validateEmail } from '@/util/validation';

export default {
  name: 'NetellerWithdraw',
  props: ['accountNumber', 'amount', 'withdrawalType', 'followerResults'],
  mixins: [mixin],
  data() {
    return {
      TransferForm: {
        netellerEmial: '',
        importantNotes: ''
      },
      TransferFormRules: {
        netellerEmial: [
          {
            required: true,
            message: this.$t('withdraw.neteller.formValidation.emailReq'),
            trigger: 'blur'
          },
          {
            validator: validateEmail,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    getFormData() {
      return {
        qAccount: this.accountNumber,
        amount: this.amount,
        withdrawalType: this.withdrawalType,
        skrillEmail: this.TransferForm.netellerEmial,
        importantNotes: this.TransferForm.importantNotes,
        followerResultIds: this.followerResults
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdraw.scss';
</style>
